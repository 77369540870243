import './GraphSettings.scss';

import { allWeeks } from '../../../../data/allWeeksObject.js';

const GraphSettings = (props) => {

  const onChangeFromWeek = (event) => {
    props.onChangeFromWeek(event.target.value)
  }

  const onChangeUntilWeek = (event) => {
    props.onChangeUntilWeek(event.target.value);
  }

  const onShowFocusWeek = () => {
    props.onShowFocusWeek();
  }

  const onChangeFocusWeek = (event) => {
    props.onChangeFocusWeek(event.target.value);
  }

  const onChangeDay = (event) => {
    props.onChangeDay(event.target.id);
  }

  // TODO Render week selectors dynamically

  let weekSelectorJSX = allWeeks.map((week) => <option key={'test'+week.week} value={week.week}>{week.week}</option>);
  let daySelectorJSX = props.days.map((day, index) =>  <label key={'day-selector-'+index} className='day-selector'>
                                                  {day.title}
                                                  <div className='day-color-bar' style={{backgroundColor: day.color}}></div>
                                                  <input type='checkbox' id={index} onChange={onChangeDay}></input>
                                                </label>);

  let focusWeekSelectorJSX =  <div id='week-focus'>Kies week:
                        <select name='week-focus' onChange={onChangeFocusWeek}>
                          {weekSelectorJSX}
                        </select>
                      </div>;

  return (
    <div className='graph-settings'>
      <div id='left'>
        <div className='settings-title'>Periode</div>
        <div id='week-from'>Van week: 
          <select name='week-from' onChange={onChangeFromWeek}>
            {weekSelectorJSX}
          </select>
        </div>
        <div id='week-until'>Tot week: 
          <select name='week-until' onChange={onChangeUntilWeek}>
            {weekSelectorJSX}
          </select>
        </div>
        <hr />
        <label id='week-focus-check'>Specifieke week:
            <input type='checkbox' onChange={onShowFocusWeek}></input> 
        </label>
        {props.showFocusWeek && focusWeekSelectorJSX}
      </div>
      <div id='middle'>
        {daySelectorJSX}
      </div>
      <div id='right'>
        PLACEHOLDER
      </div>
    </div>
  )
}

export default GraphSettings;