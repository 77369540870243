import { useLocation, Link } from 'react-router-dom';

const PageNotFound = (props) => {
  const currentLocation = useLocation();

  console.error(currentLocation.pathname);


  return(
    <div className='not-found'>
      404 - Deze pagina is niet gevonden of nog onder constructie.  (..{currentLocation.pathname})
      <br />
      <Link to='/'>HOME</Link>
    </div>
  )
}

export default PageNotFound;