import { useState } from 'react';

import GraphSettings from './Settings/GraphSettings';
import GraphTypeSelector from './GraphTypeSelector/GraphTypeSelector';
import MainGraph from './MainGraph/MainGraph';

import './AveragePerHour.scss';

import { chartTypesLineMain } from '../../../library/chartTypes';

import { reportWeek30 } from '../../../data/reportWeek30';
import { reportWeek31 } from '../../../data/reportWeek31';
import { reportWeek32 } from '../../../data/reportWeek32';
import { reportWeek33 } from '../../../data/reportWeek33';
import { reportWeek34 } from '../../../data/reportWeek34';
import { reportWeek35 } from '../../../data/reportWeek35';
import { reportWeek36 } from '../../../data/reportWeek36';
import { reportWeek37 } from '../../../data/reportWeek37';
import { reportWeek38 } from '../../../data/reportWeek38';
import { reportWeek39 } from '../../../data/reportWeek39';
import { reportWeek40 } from '../../../data/reportWeek40';
import { reportWeek41 } from '../../../data/reportWeek41';
import { MdStackedLineChart } from 'react-icons/md';

import { days } from '../../../library/days';

const reports = {
  30: reportWeek30,
  31: reportWeek31,
  32: reportWeek32,
  33: reportWeek33,
  34: reportWeek34,
  35: reportWeek35,
  36: reportWeek36,
  37: reportWeek37,
  38: reportWeek38,
  39: reportWeek39,
  40: reportWeek40,
  41: reportWeek41,
}

function parseData(weeks, requestedChart) {
  let column = 0;
  let colName = '';
  let percentage = false;

  let chosenChart = chartTypesLineMain.find((type => type.name === requestedChart));

  column = chosenChart.column;
  colName = chosenChart.colName;
  percentage = chosenChart.settings.percentage;

  let dataCalls = [];

  for (let i = 0; i < weeks.length; i++) {
    const index = reports[weeks[i]].findIndex(x => x[column] === colName);

    let weekObject = {
      monday: [],
      tuesday: [], 
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    }

    let day = '';

    for (let j = index + 1; j < index + 52; j++) {
      if (j < index + 10) {
        day = 'monday';
      } else if (j < index + 19) {
        day = 'tuesday'; 
      } else if (j < index + 28) {
        day = 'wednesday';
      } else if (j < index + 37) {
        day = 'thursday';
      } else if (j < index + 46) {
        day = 'friday';
      } else if (j < index + 53) {
        day = 'saturday';
      }

      let currNumber = reports[weeks[i]][j][column];

      if (percentage && typeof currNumber === 'string') {
        currNumber = currNumber.substring(0, currNumber.length - 1);
      }

      if (percentage && currNumber < 1.00001) { 
        currNumber = currNumber * 100;
      }
 
      currNumber = parseInt(currNumber, 10);
      
      weekObject[day].push(currNumber); 
    }

    dataCalls.push(weekObject);
  }
  return dataCalls;
}

function prepareData(data) {
  let tempData = []
  // let exampleDataPoint = {name: '08:00', avg: 19, mo: 32, tu: 19, we: 12, th: 19, fr: 19, sa: 14}

  let timeNames = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'];
  let dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  for (let i = 0; i < 9; i++) {                       // FOR EVERY POSSIBLE TIME
    let datapoint = {};
    datapoint.name = timeNames[i];
    let weeklyAvg = 0;
  
    for (let week = 0; week < data.length; week++) {  // FOR EVERY WEEK IN DATA
      for (let j = 0; j < 6; j++) { 
        let currData = data[week][dayNames[j]][i]; 

        if (currData === undefined) {
          currData = 0;
        }

        if (datapoint[dayNames[j]] === undefined) {
          datapoint[dayNames[j]] = currData;
        } else {
          datapoint[dayNames[j]] = datapoint[dayNames[j]] + currData; 
        }
      }
    }

    for (let day = 0; day < dayNames.length; day++) {
      datapoint[dayNames[day]] = (datapoint[dayNames[day]] / data.length).toFixed(2);
      weeklyAvg = parseFloat(weeklyAvg) + parseFloat(datapoint[dayNames[day]]);
    }

    if (i < 6) {
      weeklyAvg = weeklyAvg / 6;
    } else {
      weeklyAvg = weeklyAvg / 5;
    }
    
    datapoint.avg = weeklyAvg;  
    tempData.push(datapoint);
  }
  return tempData;
}

const getChartSettings = (requestedChart) => {
  let settings = {};
  settings.fontSize = window.innerWidth / 100;

  let chosenChart = chartTypesLineMain.find((type => type.name === requestedChart));
  settings.title = chosenChart.settings.title;
  settings.domain = chosenChart.settings.domain;
  settings.description = chosenChart.settings.description;
  settings.percentage = chosenChart.settings.percentage;
  
  return settings;
}

const AveragePerHour = (props) => {

  const [fromWeek, setFromWeek] = useState([30]);
  const [untilWeek, setUntilWeek] = useState([30]);
  const [selectedWeeks, setSelectedWeeks] = useState([30]);
  const [shownDays, setShownDays] = useState([]);
  const [activeGraph, setActiveGraph] = useState('queued');
  const [focusWeek, setFocusWeek] = useState([false, [30]]);

  const mainChart = {};
  mainChart.name = activeGraph;
  mainChart.settings = getChartSettings(activeGraph);
  mainChart.rawData = parseData(selectedWeeks, activeGraph);
  mainChart.fullData = prepareData(mainChart.rawData); 
  mainChart.focusRawData = parseData(focusWeek[1], activeGraph);
  mainChart.focusFullData = prepareData(mainChart.focusRawData);
 
  for(let i = 0; i < mainChart.fullData.length; i++) {
    mainChart.fullData[i].focus = mainChart.focusFullData[i].avg;
  }

  const calcSelectedWeeks = (start, end) => {
    let weeks = [];
    for (let i = parseInt(start); i <= end; i++) {
      weeks.push(i);
    }
    setSelectedWeeks(weeks);
  }

  const onChangeFromWeek = (week) => {
    setFromWeek([week]);
    calcSelectedWeeks(week, untilWeek);
  }

  const onChangeUntilWeek = (week) => {
    setUntilWeek([week]);
    calcSelectedWeeks(fromWeek, week);
  }

  const onShowFocusWeek = () => {
    let tempFocus = [...focusWeek];
    if (tempFocus[0]) {
      tempFocus = [false, [30]]
    } else {
      tempFocus[0] = !tempFocus[0];
    }

    setFocusWeek(tempFocus);
  }

  const onChangeFocusWeek = (week) => {
    let tempFocus = [...focusWeek];
    tempFocus[1] = [week];
    setFocusWeek(tempFocus);
  }

  const onChangeDay = (day) => {
    day = parseInt(day, 10);

    let tempDays = [...shownDays];
    if (tempDays.includes(day)) {
      tempDays = tempDays.filter((d) => d !== day);
    } else {
      tempDays.push(day);
      tempDays.sort(function(a, b){return a-b});
    }

    setShownDays(tempDays);
  }

  const onChangeGraphType = (type) => {
    setActiveGraph(type); 
  }

  return(
    <div className='main-view'>
      <GraphSettings  onChangeFromWeek={onChangeFromWeek} 
                      onChangeUntilWeek={onChangeUntilWeek} 
                      onShowFocusWeek={onShowFocusWeek}
                      showFocusWeek={focusWeek[0]}
                      onChangeFocusWeek={onChangeFocusWeek}
                      onChangeDay={onChangeDay} 
                      days={days} />
      <GraphTypeSelector active={activeGraph} onChangeGraphType={onChangeGraphType} />
      <MainGraph  settings={mainChart.settings} 
                  data={mainChart.fullData} 
                  focusWeek={focusWeek}
                  focusData={mainChart.focusFullData}
                  shownDays={shownDays} 
                  days={days} />
    </div>
  )
}

export default AveragePerHour;