import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

import { IconContext } from 'react-icons/lib';
import { BsQuestionCircleFill } from 'react-icons/bs';

import './MainGraph.scss';
import CustomTooltip from '../../../Global/CustomTooltip';

const MainGraph = (props) => {

  const mainColor = '#0ab8cf'
  const secondaryColor = '#078f5b'

  // const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  // const dayNames = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];

  let shownDaysJSX = props.shownDays.map((day) => 
    <Line type='monotone' 
          name={props.days[day].title} 
          isAnimationActive={false} 
          key={'line-'+props.days[day].name} 
          dataKey={props.days[day].name} 
          stroke={props.days[day].color} 
          strokeWidth={3} 
          dot={false} />
  )

  let focusJSX;

  if(props.focusWeek[0]) {
    focusJSX = <Line  type='monotone' 
                      name={'Week ' + props.focusWeek[1][0]} 
                      isAnimationActive={false} 
                      dataKey='focus' 
                      stroke={secondaryColor} 
                      strokeWidth={3}
                      dot={false} />
  }

  const numberFormatter = (num) => {
    let tempNum = Math.round(num*100) / 100; 
    tempNum = tickFormatter(tempNum);
    return tempNum;
  }

  const tickFormatter = (tickItem) => {
    if(props.settings.percentage) {
      return tickItem + '%';
    } else {
      return tickItem;
    }
  }

  return (
    <div className='graph-wrapper'>
      <div className='graph-title'>
        {props.settings.title}
        <CustomTooltip description={props.settings.description} />
      </div>
      <div className='main-graph'>
        <ResponsiveContainer width='95%' height='100%' >
          <LineChart data={props.data} margin={{top: 15, right: 50, bottom: 5, left: 0}}>
            <Legend verticalAlign="top" height={36}/>
            <Line type='monotone' name='Gemiddelde' dataKey='avg' stroke={mainColor} strokeWidth={8} />
            {shownDaysJSX}
            {focusJSX}
            <CartesianGrid stroke='#0ab8cf' strokeDasharray='5 5' />
            <XAxis dataKey='name' tick={{fontSize: props.settings.fontSize}} />
            <YAxis tick={{fontSize: props.settings.fontSize}} tickCount={5} tickFormatter={tickFormatter} domain={props.settings.domain} />
            <Tooltip contentStyle={{background: 'rgb(170, 189, 191, 0.0)', borderRadius: '15px', fontSize: props.settings.fontSize}} formatter={numberFormatter} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default MainGraph;

// TODO Customize Legend
// TODO Add additional information (dates, which line is which day etc.)
// TODO Add switch to see raw data behind graph