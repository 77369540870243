export const reportWeek36 = [
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Start Time:",
  "Column2": "05-09-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "End Time:",
  "Column2": "12-09-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Time Zone:",
  "Column2": "(GMT+02:00) Europe\/Amsterdam",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date Run:",
  "Column2": "12-09-2021, 23:05",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Name:",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Service Id:",
  "Column2": "Klantenservice-NijBeu-CC@huuskes.yestelecom.nl",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Activity",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Calls Queued",
  "Column4": "Calls Escaped",
  "Column5": "Calls Abandoned",
  "Column6": "Calls Presented",
  "Column7": "Calls Answered",
  "Column8": "Calls Answered In 30 secs",
  "Column9": "% Calls Answered In 30 secs",
  "Column10": "% Answered Calls Answered In 30 secs",
  "Column11": "Calls Overflowed - Time",
  "Column12": "Calls Bounced",
  "Column13": "Calls Bounced- Transferred",
  "Column14": "Calls Transferred",
  "Column15": "Calls Stranded",
  "Column16": "Calls Stranded-Unavailable"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "1",
  "Column6": "16",
  "Column7": "15",
  "Column8": "14",
  "Column9": "93.3%",
  "Column10": "93.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "24",
  "Column4": "0",
  "Column5": "1",
  "Column6": "81",
  "Column7": "23",
  "Column8": "16",
  "Column9": "69.6%",
  "Column10": "69.6%",
  "Column11": "0",
  "Column12": "52",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "2",
  "Column6": "34",
  "Column7": "24",
  "Column8": "7",
  "Column9": "29.2%",
  "Column10": "29.2%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "28",
  "Column4": "0",
  "Column5": "5",
  "Column6": "30",
  "Column7": "23",
  "Column8": "10",
  "Column9": "43.5%",
  "Column10": "43.5%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "3",
  "Column6": "18",
  "Column7": "14",
  "Column8": "9",
  "Column9": "64.3%",
  "Column10": "64.3%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "22",
  "Column4": "0",
  "Column5": "2",
  "Column6": "27",
  "Column7": "21",
  "Column8": "17",
  "Column9": "81.0%",
  "Column10": "81.0%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "0",
  "Column6": "26",
  "Column7": "20",
  "Column8": "11",
  "Column9": "55.0%",
  "Column10": "55.0%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "12",
  "Column4": "0",
  "Column5": "1",
  "Column6": "12",
  "Column7": "11",
  "Column8": "10",
  "Column9": "90.9%",
  "Column10": "90.9%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "8",
  "Column4": "0",
  "Column5": "0",
  "Column6": "8",
  "Column7": "8",
  "Column8": "7",
  "Column9": "87.5%",
  "Column10": "87.5%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "0",
  "Column6": "17",
  "Column7": "16",
  "Column8": "12",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "30",
  "Column4": "0",
  "Column5": "2",
  "Column6": "48",
  "Column7": "25",
  "Column8": "9",
  "Column9": "36.0%",
  "Column10": "36.0%",
  "Column11": "0",
  "Column12": "19",
  "Column13": "0",
  "Column14": "3",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "27",
  "Column4": "0",
  "Column5": "2",
  "Column6": "51",
  "Column7": "23",
  "Column8": "11",
  "Column9": "47.8%",
  "Column10": "47.8%",
  "Column11": "0",
  "Column12": "24",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "2",
  "Column6": "27",
  "Column7": "14",
  "Column8": "6",
  "Column9": "42.9%",
  "Column10": "42.9%",
  "Column11": "0",
  "Column12": "10",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "1",
  "Column6": "20",
  "Column7": "14",
  "Column8": "7",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "11",
  "Column4": "0",
  "Column5": "1",
  "Column6": "13",
  "Column7": "8",
  "Column8": "6",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "2",
  "Column6": "22",
  "Column7": "17",
  "Column8": "9",
  "Column9": "52.9%",
  "Column10": "52.9%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "7",
  "Column4": "0",
  "Column5": "0",
  "Column6": "9",
  "Column7": "7",
  "Column8": "5",
  "Column9": "71.4%",
  "Column10": "71.4%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "2",
  "Column4": "0",
  "Column5": "1",
  "Column6": "3",
  "Column7": "1",
  "Column8": "1",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "2",
  "Column6": "33",
  "Column7": "19",
  "Column8": "15",
  "Column9": "78.9%",
  "Column10": "78.9%",
  "Column11": "0",
  "Column12": "12",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "30",
  "Column4": "0",
  "Column5": "2",
  "Column6": "36",
  "Column7": "27",
  "Column8": "15",
  "Column9": "55.6%",
  "Column10": "55.6%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "31",
  "Column4": "0",
  "Column5": "7",
  "Column6": "42",
  "Column7": "22",
  "Column8": "6",
  "Column9": "27.3%",
  "Column10": "27.3%",
  "Column11": "0",
  "Column12": "19",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "41",
  "Column4": "0",
  "Column5": "3",
  "Column6": "45",
  "Column7": "38",
  "Column8": "10",
  "Column9": "26.3%",
  "Column10": "26.3%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "3",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "1",
  "Column6": "17",
  "Column7": "14",
  "Column8": "8",
  "Column9": "57.1%",
  "Column10": "57.1%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "4",
  "Column6": "26",
  "Column7": "17",
  "Column8": "10",
  "Column9": "58.8%",
  "Column10": "58.8%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "19",
  "Column4": "0",
  "Column5": "3",
  "Column6": "22",
  "Column7": "14",
  "Column8": "4",
  "Column9": "28.6%",
  "Column10": "28.6%",
  "Column11": "0",
  "Column12": "8",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "12",
  "Column4": "0",
  "Column5": "2",
  "Column6": "17",
  "Column7": "8",
  "Column8": "4",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "8",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "6",
  "Column4": "0",
  "Column5": "1",
  "Column6": "10",
  "Column7": "5",
  "Column8": "4",
  "Column9": "80.0%",
  "Column10": "80.0%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "0",
  "Column6": "22",
  "Column7": "21",
  "Column8": "18",
  "Column9": "85.7%",
  "Column10": "85.7%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "23",
  "Column4": "0",
  "Column5": "4",
  "Column6": "24",
  "Column7": "18",
  "Column8": "15",
  "Column9": "83.3%",
  "Column10": "83.3%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "30",
  "Column4": "0",
  "Column5": "2",
  "Column6": "30",
  "Column7": "28",
  "Column8": "24",
  "Column9": "85.7%",
  "Column10": "85.7%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "23",
  "Column4": "0",
  "Column5": "2",
  "Column6": "25",
  "Column7": "21",
  "Column8": "19",
  "Column9": "90.5%",
  "Column10": "90.5%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "1",
  "Column6": "13",
  "Column7": "12",
  "Column8": "11",
  "Column9": "91.7%",
  "Column10": "91.7%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "2",
  "Column6": "35",
  "Column7": "25",
  "Column8": "13",
  "Column9": "52.0%",
  "Column10": "52.0%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "32",
  "Column4": "0",
  "Column5": "8",
  "Column6": "26",
  "Column7": "24",
  "Column8": "8",
  "Column9": "33.3%",
  "Column10": "33.3%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "0",
  "Column6": "11",
  "Column7": "11",
  "Column8": "6",
  "Column9": "54.5%",
  "Column10": "54.5%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "2",
  "Column4": "0",
  "Column5": "0",
  "Column6": "2",
  "Column7": "2",
  "Column8": "2",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "0",
  "Column6": "19",
  "Column7": "16",
  "Column8": "11",
  "Column9": "68.8%",
  "Column10": "68.8%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "28",
  "Column4": "0",
  "Column5": "4",
  "Column6": "36",
  "Column7": "22",
  "Column8": "15",
  "Column9": "68.2%",
  "Column10": "68.2%",
  "Column11": "0",
  "Column12": "11",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "25",
  "Column4": "0",
  "Column5": "1",
  "Column6": "25",
  "Column7": "20",
  "Column8": "12",
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "25",
  "Column4": "0",
  "Column5": "2",
  "Column6": "36",
  "Column7": "21",
  "Column8": "12",
  "Column9": "57.1%",
  "Column10": "57.1%",
  "Column11": "0",
  "Column12": "12",
  "Column13": "0",
  "Column14": "5",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "2",
  "Column6": "22",
  "Column7": "14",
  "Column8": "8",
  "Column9": "57.1%",
  "Column10": "57.1%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "4",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "1",
  "Column6": "27",
  "Column7": "14",
  "Column8": "6",
  "Column9": "42.9%",
  "Column10": "42.9%",
  "Column11": "0",
  "Column12": "11",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "6",
  "Column6": "34",
  "Column7": "21",
  "Column8": "8",
  "Column9": "38.1%",
  "Column10": "38.1%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "0",
  "Column6": "23",
  "Column7": "12",
  "Column8": "6",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "10",
  "Column13": "0",
  "Column14": "6",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "4",
  "Column4": "0",
  "Column5": "1",
  "Column6": "11",
  "Column7": "3",
  "Column8": "0",
  "Column9": "0.0%",
  "Column10": "0.0%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "1",
  "Column6": "15",
  "Column7": "12",
  "Column8": "10",
  "Column9": "83.3%",
  "Column10": "83.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "2",
  "Column6": "16",
  "Column7": "14",
  "Column8": "4",
  "Column9": "28.6%",
  "Column10": "28.6%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "2",
  "Column6": "31",
  "Column7": "25",
  "Column8": "3",
  "Column9": "12.0%",
  "Column10": "12.0%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "26",
  "Column4": "0",
  "Column5": "5",
  "Column6": "25",
  "Column7": "22",
  "Column8": "3",
  "Column9": "13.6%",
  "Column10": "13.6%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "9",
  "Column4": "0",
  "Column5": "1",
  "Column6": "10",
  "Column7": "8",
  "Column8": "5",
  "Column9": "62.5%",
  "Column10": "62.5%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "5",
  "Column4": "0",
  "Column5": "0",
  "Column6": "5",
  "Column7": "5",
  "Column8": "3",
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "987",
  "Column4": "0",
  "Column5": "98",
  "Column6": "1233",
  "Column7": "839",
  "Column8": "465",
  "Column9": "55.4%",
  "Column10": "55.4%",
  "Column11": "0",
  "Column12": "329",
  "Column13": "0",
  "Column14": "50",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "High Water Marks",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Longest Wait Time",
  "Column4": "Longest Answer Time",
  "Column5": "Longest Wait Abandoned",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:08",
  "Column4": "00:00:32",
  "Column5": "00:00:03",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:08",
  "Column4": "00:02:10",
  "Column5": "00:00:16",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:52",
  "Column4": "00:03:05",
  "Column5": "00:02:12",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:21",
  "Column4": "00:05:27",
  "Column5": "00:01:33",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:23",
  "Column4": "00:02:27",
  "Column5": "00:08:23",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:16",
  "Column4": "00:00:47",
  "Column5": "00:05:16",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:13",
  "Column4": "00:05:17",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:46",
  "Column4": "00:00:50",
  "Column5": "00:00:07",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "06-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:31",
  "Column4": "00:00:33",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:53",
  "Column4": "00:00:55",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:54",
  "Column4": "00:03:44",
  "Column5": "00:04:54",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:11",
  "Column4": "00:03:47",
  "Column5": "00:04:11",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:55",
  "Column4": "00:02:07",
  "Column5": "00:01:26",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:47",
  "Column4": "00:01:57",
  "Column5": "00:00:30",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:46",
  "Column4": "00:01:47",
  "Column5": "00:00:20",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:03",
  "Column4": "00:04:05",
  "Column5": "00:03:43",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:42",
  "Column4": "00:00:51",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "07-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:20",
  "Column4": "00:00:15",
  "Column5": "00:00:20",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:37",
  "Column4": "00:01:46",
  "Column5": "00:00:44",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:56",
  "Column4": "00:04:01",
  "Column5": "00:00:31",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:21",
  "Column4": "00:05:25",
  "Column5": "00:02:08",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:49",
  "Column4": "00:03:54",
  "Column5": "00:00:54",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:15",
  "Column4": "00:03:26",
  "Column5": "00:01:07",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:52",
  "Column4": "00:01:57",
  "Column5": "00:00:44",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:03",
  "Column4": "00:04:46",
  "Column5": "00:00:42",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:42",
  "Column4": "00:02:45",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "08-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:19",
  "Column4": "00:01:21",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:44",
  "Column4": "00:00:58",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:12",
  "Column4": "00:01:16",
  "Column5": "00:00:10",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:32",
  "Column4": "00:01:44",
  "Column5": "00:00:20",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:05",
  "Column4": "00:00:45",
  "Column5": "00:01:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:30",
  "Column4": "00:00:42",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:28",
  "Column4": "00:02:30",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:06:12",
  "Column4": "00:06:14",
  "Column5": "00:01:51",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:05",
  "Column4": "00:02:07",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "09-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:13",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:14",
  "Column4": "00:02:34",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:26",
  "Column4": "00:02:03",
  "Column5": "00:02:26",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:05",
  "Column4": "00:01:54",
  "Column5": "00:01:08",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:04",
  "Column4": "00:03:06",
  "Column5": "00:00:06",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:56",
  "Column4": "00:02:02",
  "Column5": "00:00:40",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:33",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:29",
  "Column4": "00:03:33",
  "Column5": "00:01:50",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:28",
  "Column4": "00:05:19",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "10-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:36",
  "Column4": "00:01:53",
  "Column5": "00:01:14",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:55",
  "Column4": "00:00:36",
  "Column5": "00:00:20",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:10",
  "Column4": "00:05:11",
  "Column5": "00:03:46",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:07:48",
  "Column4": "00:07:54",
  "Column5": "00:01:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:05",
  "Column4": "00:08:07",
  "Column5": "00:02:15",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:04",
  "Column4": "00:04:06",
  "Column5": "00:00:12",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "11-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:04",
  "Column4": "00:01:06",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:23",
  "Column4": "00:08:07",
  "Column5": "00:08:23",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Pagina 1 van 1",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Proprietary and Confidential, do not copy, duplicate or distribute",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 }
]