import { reportWeek30 } from '../data/reportWeek30';
import { reportWeek31 } from '../data/reportWeek31';
import { reportWeek32 } from '../data/reportWeek32';
import { reportWeek33 } from '../data/reportWeek33';
import { reportWeek34 } from '../data/reportWeek34';
import { reportWeek35 } from '../data/reportWeek35';
import { reportWeek36 } from '../data/reportWeek36';
import { reportWeek37 } from '../data/reportWeek37';
import { reportWeek38 } from '../data/reportWeek38';
import { reportWeek39 } from '../data/reportWeek39';
import { reportWeek40 } from '../data/reportWeek40';
import { reportWeek41 } from '../data/reportWeek41';

import { days } from '../library/days';

import { chartTypesPieTile } from '../library/chartTypes';

const reports = {
  30: reportWeek30,
  31: reportWeek31,
  32: reportWeek32,
  33: reportWeek33,
  34: reportWeek34,
  35: reportWeek35,
  36: reportWeek36,
  37: reportWeek37,
  38: reportWeek38,
  39: reportWeek39,
  40: reportWeek40,
  41: reportWeek41,
}

const getPieData = (week, requestedChart) => {

  // We want data to look like:
  // let Data = [
  //   {name: 'Maandag', value: 10},
  //   {name: 'Dinsdag', value: 10},
  //   {name: 'Woensdag', value: 10},
  //   {name: 'Donderdag', value: 10},
  //   {name: 'Vrijdag', value: 10},
  //   {name: 'Zaterdag', value: 10}
  // ];

  let column = 0;
  let colName = '';

  let chosenChart = chartTypesPieTile.find((type => type.name === requestedChart));

  column = chosenChart.column;
  colName = chosenChart.colName;

  const index = reports[week].findIndex(x => x[column] === colName);
  let data = [];

  for (let i = 0; i < 6; i++) {
    let dataPoint = {name: days[i].title, value: 0};
    let dayLength = i > 4 ? 6 : 9;

    for (let j = 0; j < dayLength; j++) {
      dataPoint.value = dataPoint.value + parseInt(reports[week][index + 1 + (i * 9) + j][column]);
    }

    data.push(dataPoint);
  }

  let reverseData = data.reverse();
  
  return reverseData;
}

export default getPieData;