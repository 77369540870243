export const reportWeek33 = [
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Start Time:",
  "Column2": "15-08-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "End Time:",
  "Column2": "22-08-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Time Zone:",
  "Column2": "(GMT+02:00) Europe\/Amsterdam",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date Run:",
  "Column2": "22-08-2021, 23:02",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Name:",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Service Id:",
  "Column2": "Klantenservice-NijBeu-CC@huuskes.yestelecom.nl",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Activity",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Calls Queued",
  "Column4": "Calls Escaped",
  "Column5": "Calls Abandoned",
  "Column6": "Calls Presented",
  "Column7": "Calls Answered",
  "Column8": "Calls Answered In 30 secs",
  "Column9": "% Calls Answered In 30 secs",
  "Column10": "% Answered Calls Answered In 30 secs",
  "Column11": "Calls Overflowed - Time",
  "Column12": "Calls Bounced",
  "Column13": "Calls Bounced- Transferred",
  "Column14": "Calls Transferred",
  "Column15": "Calls Stranded",
  "Column16": "Calls Stranded-Unavailable"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 14,
  "Column4": 0,
  "Column5": 1,
  "Column6": 23,
  "Column7": 12,
  "Column8": 8,
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": 0,
  "Column12": 9,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 27,
  "Column4": 0,
  "Column5": 1,
  "Column6": 52,
  "Column7": 26,
  "Column8": 11,
  "Column9": "42.3%",
  "Column10": "42.3%",
  "Column11": 0,
  "Column12": 26,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 37,
  "Column4": 0,
  "Column5": 4,
  "Column6": 38,
  "Column7": 31,
  "Column8": 12,
  "Column9": "38.7%",
  "Column10": "38.7%",
  "Column11": 0,
  "Column12": 6,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 32,
  "Column4": 0,
  "Column5": 1,
  "Column6": 43,
  "Column7": 31,
  "Column8": 13,
  "Column9": "41.9%",
  "Column10": "41.9%",
  "Column11": 0,
  "Column12": 12,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 16,
  "Column4": 0,
  "Column5": 1,
  "Column6": 24,
  "Column7": 14,
  "Column8": 7,
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": 0,
  "Column12": 9,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 22,
  "Column4": 0,
  "Column5": 5,
  "Column6": 41,
  "Column7": 17,
  "Column8": 3,
  "Column9": "17.6%",
  "Column10": "17.6%",
  "Column11": 0,
  "Column12": 21,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 29,
  "Column4": 0,
  "Column5": 4,
  "Column6": 23,
  "Column7": 19,
  "Column8": 8,
  "Column9": "42.1%",
  "Column10": "42.1%",
  "Column11": 0,
  "Column12": 3,
  "Column13": 0,
  "Column14": 6,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 10,
  "Column4": 0,
  "Column5": 1,
  "Column6": 9,
  "Column7": 8,
  "Column8": 8,
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 6,
  "Column4": 0,
  "Column5": 0,
  "Column6": 6,
  "Column7": 5,
  "Column8": 5,
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 15,
  "Column4": 0,
  "Column5": 0,
  "Column6": 18,
  "Column7": 15,
  "Column8": 13,
  "Column9": "86.7%",
  "Column10": "86.7%",
  "Column11": 0,
  "Column12": 3,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 26,
  "Column4": 0,
  "Column5": 2,
  "Column6": 30,
  "Column7": 23,
  "Column8": 14,
  "Column9": "60.9%",
  "Column10": "60.9%",
  "Column11": 0,
  "Column12": 4,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 18,
  "Column4": 0,
  "Column5": 2,
  "Column6": 20,
  "Column7": 14,
  "Column8": 12,
  "Column9": "85.7%",
  "Column10": "85.7%",
  "Column11": 0,
  "Column12": 3,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 17,
  "Column4": 0,
  "Column5": 0,
  "Column6": 20,
  "Column7": 16,
  "Column8": 7,
  "Column9": "43.8%",
  "Column10": "43.8%",
  "Column11": 0,
  "Column12": 4,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 11,
  "Column4": 0,
  "Column5": 0,
  "Column6": 13,
  "Column7": 11,
  "Column8": 8,
  "Column9": "72.7%",
  "Column10": "72.7%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 8,
  "Column4": 0,
  "Column5": 0,
  "Column6": 11,
  "Column7": 8,
  "Column8": 5,
  "Column9": "62.5%",
  "Column10": "62.5%",
  "Column11": 0,
  "Column12": 3,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 17,
  "Column4": 0,
  "Column5": 0,
  "Column6": 21,
  "Column7": 17,
  "Column8": 10,
  "Column9": "58.8%",
  "Column10": "58.8%",
  "Column11": 0,
  "Column12": 4,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 9,
  "Column4": 0,
  "Column5": 3,
  "Column6": 15,
  "Column7": 5,
  "Column8": 1,
  "Column9": "20.0%",
  "Column10": "20.0%",
  "Column11": 0,
  "Column12": 7,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 5,
  "Column4": 0,
  "Column5": 1,
  "Column6": 4,
  "Column7": 4,
  "Column8": 4,
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 17,
  "Column4": 0,
  "Column5": 0,
  "Column6": 31,
  "Column7": 17,
  "Column8": 10,
  "Column9": "58.8%",
  "Column10": "58.8%",
  "Column11": 0,
  "Column12": 12,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 21,
  "Column4": 0,
  "Column5": 1,
  "Column6": 29,
  "Column7": 20,
  "Column8": 15,
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": 0,
  "Column12": 9,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 24,
  "Column4": 0,
  "Column5": 2,
  "Column6": 34,
  "Column7": 20,
  "Column8": 13,
  "Column9": "65.0%",
  "Column10": "65.0%",
  "Column11": 0,
  "Column12": 12,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 34,
  "Column4": 0,
  "Column5": 0,
  "Column6": 50,
  "Column7": 34,
  "Column8": 16,
  "Column9": "47.1%",
  "Column10": "47.1%",
  "Column11": 0,
  "Column12": 16,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 16,
  "Column4": 0,
  "Column5": 2,
  "Column6": 22,
  "Column7": 14,
  "Column8": 8,
  "Column9": "57.1%",
  "Column10": "57.1%",
  "Column11": 0,
  "Column12": 7,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 21,
  "Column4": 0,
  "Column5": 1,
  "Column6": 22,
  "Column7": 19,
  "Column8": 15,
  "Column9": "78.9%",
  "Column10": "78.9%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 14,
  "Column4": 0,
  "Column5": 1,
  "Column6": 15,
  "Column7": 13,
  "Column8": 7,
  "Column9": "53.8%",
  "Column10": "53.8%",
  "Column11": 0,
  "Column12": 3,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 6,
  "Column4": 0,
  "Column5": 0,
  "Column6": 6,
  "Column7": 6,
  "Column8": 4,
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 3,
  "Column4": 0,
  "Column5": 0,
  "Column6": 4,
  "Column7": 3,
  "Column8": 2,
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": 0,
  "Column12": 1,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 18,
  "Column4": 0,
  "Column5": 1,
  "Column6": 18,
  "Column7": 15,
  "Column8": 10,
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 28,
  "Column4": 0,
  "Column5": 3,
  "Column6": 37,
  "Column7": 21,
  "Column8": 10,
  "Column9": "47.6%",
  "Column10": "47.6%",
  "Column11": 0,
  "Column12": 13,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 24,
  "Column4": 0,
  "Column5": 1,
  "Column6": 60,
  "Column7": 23,
  "Column8": 9,
  "Column9": "39.1%",
  "Column10": "39.1%",
  "Column11": 0,
  "Column12": 34,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 24,
  "Column4": 0,
  "Column5": 2,
  "Column6": 31,
  "Column7": 22,
  "Column8": 13,
  "Column9": "59.1%",
  "Column10": "59.1%",
  "Column11": 0,
  "Column12": 9,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 12,
  "Column4": 0,
  "Column5": 0,
  "Column6": 15,
  "Column7": 9,
  "Column8": 6,
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": 0,
  "Column12": 5,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 12,
  "Column4": 0,
  "Column5": 1,
  "Column6": 17,
  "Column7": 12,
  "Column8": 4,
  "Column9": "33.3%",
  "Column10": "33.3%",
  "Column11": 0,
  "Column12": 5,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 14,
  "Column4": 0,
  "Column5": 0,
  "Column6": 21,
  "Column7": 14,
  "Column8": 8,
  "Column9": "57.1%",
  "Column10": "57.1%",
  "Column11": 0,
  "Column12": 7,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 14,
  "Column4": 0,
  "Column5": 0,
  "Column6": 25,
  "Column7": 12,
  "Column8": 5,
  "Column9": "41.7%",
  "Column10": "41.7%",
  "Column11": 0,
  "Column12": 11,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 2,
  "Column4": 0,
  "Column5": 0,
  "Column6": 2,
  "Column7": 2,
  "Column8": 2,
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 14,
  "Column4": 0,
  "Column5": 0,
  "Column6": 21,
  "Column7": 13,
  "Column8": 9,
  "Column9": "69.2%",
  "Column10": "69.2%",
  "Column11": 0,
  "Column12": 7,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 38,
  "Column4": 0,
  "Column5": 1,
  "Column6": 38,
  "Column7": 31,
  "Column8": 22,
  "Column9": "71.0%",
  "Column10": "71.0%",
  "Column11": 0,
  "Column12": 4,
  "Column13": 0,
  "Column14": 5,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 34,
  "Column4": 0,
  "Column5": 3,
  "Column6": 48,
  "Column7": 31,
  "Column8": 16,
  "Column9": "51.6%",
  "Column10": "51.6%",
  "Column11": 0,
  "Column12": 15,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 30,
  "Column4": 0,
  "Column5": 2,
  "Column6": 29,
  "Column7": 25,
  "Column8": 19,
  "Column9": "76.0%",
  "Column10": "76.0%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 3,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 11,
  "Column4": 0,
  "Column5": 1,
  "Column6": 12,
  "Column7": 10,
  "Column8": 6,
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 15,
  "Column4": 0,
  "Column5": 0,
  "Column6": 14,
  "Column7": 12,
  "Column8": 8,
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 17,
  "Column4": 0,
  "Column5": 2,
  "Column6": 26,
  "Column7": 16,
  "Column8": 2,
  "Column9": "12.5%",
  "Column10": "12.5%",
  "Column11": 0,
  "Column12": 8,
  "Column13": 0,
  "Column14": 2,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 13,
  "Column4": 0,
  "Column5": 0,
  "Column6": 18,
  "Column7": 12,
  "Column8": 9,
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": 0,
  "Column12": 5,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 10,
  "Column4": 0,
  "Column5": 0,
  "Column6": 13,
  "Column7": 9,
  "Column8": 2,
  "Column9": "22.2%",
  "Column10": "22.2%",
  "Column11": 0,
  "Column12": 4,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 13,
  "Column4": 0,
  "Column5": 0,
  "Column6": 14,
  "Column7": 12,
  "Column8": 6,
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 11,
  "Column4": 0,
  "Column5": 1,
  "Column6": 10,
  "Column7": 10,
  "Column8": 10,
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 15,
  "Column4": 0,
  "Column5": 0,
  "Column6": 16,
  "Column7": 15,
  "Column8": 13,
  "Column9": "86.7%",
  "Column10": "86.7%",
  "Column11": 0,
  "Column12": 1,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 18,
  "Column4": 0,
  "Column5": 2,
  "Column6": 21,
  "Column7": 16,
  "Column8": 9,
  "Column9": "56.2%",
  "Column10": "56.2%",
  "Column11": 0,
  "Column12": 4,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 8,
  "Column4": 0,
  "Column5": 0,
  "Column6": 10,
  "Column7": 8,
  "Column8": 5,
  "Column9": "62.5%",
  "Column10": "62.5%",
  "Column11": 0,
  "Column12": 2,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 11,
  "Column4": 0,
  "Column5": 0,
  "Column6": 11,
  "Column7": 10,
  "Column8": 6,
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 1,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 0,
  "Column4": 0,
  "Column5": 0,
  "Column6": 0,
  "Column7": 0,
  "Column8": 0,
  "Column9": "0%",
  "Column10": "0%",
  "Column11": 0,
  "Column12": 0,
  "Column13": 0,
  "Column14": 0,
  "Column15": 0,
  "Column16": 0
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": 881,
  "Column4": 0,
  "Column5": 53,
  "Column6": 1151,
  "Column7": 782,
  "Column8": 448,
  "Column9": "57.3%",
  "Column10": "57.3%",
  "Column11": 0,
  "Column12": 322,
  "Column13": 0,
  "Column14": 46,
  "Column15": 0,
  "Column16": 0
 },
 null,
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "High Water Marks",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Longest Wait Time",
  "Column4": "Longest Answer Time",
  "Column5": "Longest Wait Abandoned",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:06",
  "Column4": "00:02:19",
  "Column5": "00:00:07",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:06:10",
  "Column4": "00:06:12",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:04",
  "Column4": "00:04:19",
  "Column5": "00:01:38",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:41",
  "Column4": "00:05:44",
  "Column5": "00:02:43",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:06",
  "Column4": "00:03:08",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:04",
  "Column4": "00:05:06",
  "Column5": "00:03:30",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:55",
  "Column4": "00:03:57",
  "Column5": "00:01:43",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:17",
  "Column4": "00:00:24",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:17",
  "Column4": "00:00:22",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:40",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:10",
  "Column4": "00:02:23",
  "Column5": "00:01:03",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:31",
  "Column4": "00:00:42",
  "Column5": "00:01:31",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:09",
  "Column4": "00:03:33",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:29",
  "Column4": "00:01:31",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:26",
  "Column4": "00:01:39",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:56",
  "Column4": "00:01:05",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:13",
  "Column5": "00:00:52",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:17",
  "Column4": "00:00:25",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:00",
  "Column4": "00:02:10",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:36",
  "Column4": "00:01:44",
  "Column5": "00:01:34",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:07",
  "Column4": "00:04:14",
  "Column5": "00:00:57",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:35",
  "Column4": "00:03:40",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:41",
  "Column4": "00:01:50",
  "Column5": "00:02:41",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:15",
  "Column4": "00:01:32",
  "Column5": "00:01:28",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:07:52",
  "Column4": "00:08:08",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:33",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:39",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:59",
  "Column4": "00:02:24",
  "Column5": "00:01:48",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:20",
  "Column4": "00:03:23",
  "Column5": "00:00:57",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:25",
  "Column4": "00:02:38",
  "Column5": "00:00:06",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:31",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:04",
  "Column4": "00:04:06",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:13",
  "Column4": "00:02:55",
  "Column5": "00:08:13",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:08",
  "Column4": "00:01:10",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:28",
  "Column4": "00:01:35",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "19-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:22",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:18",
  "Column4": "00:01:27",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:54",
  "Column4": "00:01:56",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:36",
  "Column4": "00:02:47",
  "Column5": "00:00:58",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:46",
  "Column4": "00:02:05",
  "Column5": "00:00:08",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:48",
  "Column4": "00:02:17",
  "Column5": "00:01:02",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:20",
  "Column4": "00:01:15",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:06:20",
  "Column4": "00:06:21",
  "Column5": "00:01:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:08",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-08-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:47",
  "Column4": "00:02:22",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:49",
  "Column4": "00:01:50",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:09",
  "Column4": "00:00:28",
  "Column5": "00:01:09",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:27",
  "Column4": "00:01:41",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:59",
  "Column4": "00:01:11",
  "Column5": "00:01:59",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:13",
  "Column4": "00:02:16",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-08-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:32",
  "Column4": "00:03:47",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:13",
  "Column4": "00:08:08",
  "Column5": "00:08:13",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Pagina 1 van 1",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Proprietary and Confidential, do not copy, duplicate or distribute",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 }
]