export const reportWeek37 = [
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Start Time:",
  "Column2": "12-09-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "End Time:",
  "Column2": "19-09-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Time Zone:",
  "Column2": "(GMT+02:00) Europe\/Amsterdam",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date Run:",
  "Column2": "19-09-2021, 23:07",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Name:",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Service Id:",
  "Column2": "Klantenservice-NijBeu-CC@huuskes.yestelecom.nl",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Activity",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Calls Queued",
  "Column4": "Calls Escaped",
  "Column5": "Calls Abandoned",
  "Column6": "Calls Presented",
  "Column7": "Calls Answered",
  "Column8": "Calls Answered In 30 secs",
  "Column9": "% Calls Answered In 30 secs",
  "Column10": "% Answered Calls Answered In 30 secs",
  "Column11": "Calls Overflowed - Time",
  "Column12": "Calls Bounced",
  "Column13": "Calls Bounced- Transferred",
  "Column14": "Calls Transferred",
  "Column15": "Calls Stranded",
  "Column16": "Calls Stranded-Unavailable"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "1",
  "Column6": "19",
  "Column7": "16",
  "Column8": "13",
  "Column9": "81.2%",
  "Column10": "81.2%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "1",
  "Column6": "24",
  "Column7": "18",
  "Column8": "15",
  "Column9": "83.3%",
  "Column10": "83.3%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "24",
  "Column4": "0",
  "Column5": "0",
  "Column6": "28",
  "Column7": "24",
  "Column8": "19",
  "Column9": "79.2%",
  "Column10": "79.2%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "26",
  "Column4": "0",
  "Column5": "3",
  "Column6": "37",
  "Column7": "22",
  "Column8": "13",
  "Column9": "59.1%",
  "Column10": "59.1%",
  "Column11": "0",
  "Column12": "13",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "1",
  "Column6": "15",
  "Column7": "12",
  "Column8": "7",
  "Column9": "58.3%",
  "Column10": "58.3%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "0",
  "Column6": "15",
  "Column7": "15",
  "Column8": "15",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "24",
  "Column4": "0",
  "Column5": "1",
  "Column6": "36",
  "Column7": "23",
  "Column8": "14",
  "Column9": "60.9%",
  "Column10": "60.9%",
  "Column11": "0",
  "Column12": "13",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "5",
  "Column4": "0",
  "Column5": "1",
  "Column6": "5",
  "Column7": "3",
  "Column8": "2",
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "0",
  "Column6": "12",
  "Column7": "10",
  "Column8": "10",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "3",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "12",
  "Column4": "0",
  "Column5": "0",
  "Column6": "16",
  "Column7": "12",
  "Column8": "9",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "0",
  "Column6": "16",
  "Column7": "16",
  "Column8": "14",
  "Column9": "87.5%",
  "Column10": "87.5%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "24",
  "Column4": "0",
  "Column5": "1",
  "Column6": "24",
  "Column7": "22",
  "Column8": "19",
  "Column9": "86.4%",
  "Column10": "86.4%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "24",
  "Column4": "0",
  "Column5": "0",
  "Column6": "32",
  "Column7": "24",
  "Column8": "20",
  "Column9": "83.3%",
  "Column10": "83.3%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "2",
  "Column6": "24",
  "Column7": "9",
  "Column8": "6",
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": "0",
  "Column12": "13",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "1",
  "Column6": "29",
  "Column7": "17",
  "Column8": "11",
  "Column9": "64.7%",
  "Column10": "64.7%",
  "Column11": "0",
  "Column12": "10",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "19",
  "Column4": "0",
  "Column5": "1",
  "Column6": "20",
  "Column7": "19",
  "Column8": "16",
  "Column9": "84.2%",
  "Column10": "84.2%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "1",
  "Column6": "13",
  "Column7": "11",
  "Column8": "6",
  "Column9": "54.5%",
  "Column10": "54.5%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "12",
  "Column4": "0",
  "Column5": "3",
  "Column6": "8",
  "Column7": "4",
  "Column8": "1",
  "Column9": "25.0%",
  "Column10": "25.0%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "5",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "19",
  "Column4": "0",
  "Column5": "1",
  "Column6": "64",
  "Column7": "16",
  "Column8": "8",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "47",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "1",
  "Column6": "42",
  "Column7": "28",
  "Column8": "12",
  "Column9": "42.9%",
  "Column10": "42.9%",
  "Column11": "0",
  "Column12": "14",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "0",
  "Column6": "31",
  "Column7": "20",
  "Column8": "11",
  "Column9": "55.0%",
  "Column10": "55.0%",
  "Column11": "0",
  "Column12": "11",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "28",
  "Column4": "0",
  "Column5": "1",
  "Column6": "37",
  "Column7": "26",
  "Column8": "17",
  "Column9": "65.4%",
  "Column10": "65.4%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "0",
  "Column6": "20",
  "Column7": "13",
  "Column8": "6",
  "Column9": "46.2%",
  "Column10": "46.2%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "5",
  "Column6": "36",
  "Column7": "12",
  "Column8": "7",
  "Column9": "58.3%",
  "Column10": "58.3%",
  "Column11": "0",
  "Column12": "20",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "1",
  "Column6": "24",
  "Column7": "15",
  "Column8": "14",
  "Column9": "93.3%",
  "Column10": "93.3%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "9",
  "Column4": "0",
  "Column5": "1",
  "Column6": "8",
  "Column7": "8",
  "Column8": "8",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "7",
  "Column4": "0",
  "Column5": "0",
  "Column6": "7",
  "Column7": "7",
  "Column8": "7",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "0",
  "Column6": "16",
  "Column7": "15",
  "Column8": "14",
  "Column9": "93.3%",
  "Column10": "93.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "2",
  "Column6": "34",
  "Column7": "26",
  "Column8": "18",
  "Column9": "69.2%",
  "Column10": "69.2%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "1",
  "Column6": "24",
  "Column7": "18",
  "Column8": "11",
  "Column9": "61.1%",
  "Column10": "61.1%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "1",
  "Column6": "40",
  "Column7": "20",
  "Column8": "8",
  "Column9": "40.0%",
  "Column10": "40.0%",
  "Column11": "0",
  "Column12": "19",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "12",
  "Column4": "0",
  "Column5": "1",
  "Column6": "14",
  "Column7": "11",
  "Column8": "8",
  "Column9": "72.7%",
  "Column10": "72.7%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "1",
  "Column6": "19",
  "Column7": "19",
  "Column8": "18",
  "Column9": "94.7%",
  "Column10": "94.7%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "0",
  "Column6": "21",
  "Column7": "16",
  "Column8": "11",
  "Column9": "68.8%",
  "Column10": "68.8%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "4",
  "Column6": "34",
  "Column7": "15",
  "Column8": "8",
  "Column9": "53.3%",
  "Column10": "53.3%",
  "Column11": "0",
  "Column12": "15",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "10",
  "Column4": "0",
  "Column5": "2",
  "Column6": "10",
  "Column7": "8",
  "Column8": "8",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "0",
  "Column6": "32",
  "Column7": "14",
  "Column8": "7",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "18",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "30",
  "Column4": "0",
  "Column5": "1",
  "Column6": "30",
  "Column7": "29",
  "Column8": "18",
  "Column9": "62.1%",
  "Column10": "62.1%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "1",
  "Column6": "31",
  "Column7": "28",
  "Column8": "20",
  "Column9": "71.4%",
  "Column10": "71.4%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "26",
  "Column4": "0",
  "Column5": "4",
  "Column6": "32",
  "Column7": "21",
  "Column8": "8",
  "Column9": "38.1%",
  "Column10": "38.1%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "0",
  "Column6": "21",
  "Column7": "17",
  "Column8": "13",
  "Column9": "76.5%",
  "Column10": "76.5%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "3",
  "Column6": "20",
  "Column7": "19",
  "Column8": "14",
  "Column9": "73.7%",
  "Column10": "73.7%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "1",
  "Column6": "24",
  "Column7": "20",
  "Column8": "12",
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "1",
  "Column6": "21",
  "Column7": "15",
  "Column8": "11",
  "Column9": "73.3%",
  "Column10": "73.3%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "8",
  "Column4": "0",
  "Column5": "3",
  "Column6": "5",
  "Column7": "5",
  "Column8": "5",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "4",
  "Column4": "0",
  "Column5": "0",
  "Column6": "4",
  "Column7": "4",
  "Column8": "4",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "0",
  "Column6": "16",
  "Column7": "15",
  "Column8": "11",
  "Column9": "73.3%",
  "Column10": "73.3%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "0",
  "Column6": "15",
  "Column7": "15",
  "Column8": "14",
  "Column9": "93.3%",
  "Column10": "93.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "8",
  "Column4": "0",
  "Column5": "1",
  "Column6": "7",
  "Column7": "7",
  "Column8": "6",
  "Column9": "85.7%",
  "Column10": "85.7%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "8",
  "Column4": "0",
  "Column5": "0",
  "Column6": "8",
  "Column7": "8",
  "Column8": "6",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "5",
  "Column4": "0",
  "Column5": "0",
  "Column6": "5",
  "Column7": "5",
  "Column8": "4",
  "Column9": "80.0%",
  "Column10": "80.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "872",
  "Column4": "0",
  "Column5": "54",
  "Column6": "1125",
  "Column7": "792",
  "Column8": "557",
  "Column9": "70.3%",
  "Column10": "70.3%",
  "Column11": "0",
  "Column12": "290",
  "Column13": "0",
  "Column14": "26",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "High Water Marks",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Longest Wait Time",
  "Column4": "Longest Answer Time",
  "Column5": "Longest Wait Abandoned",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:20",
  "Column4": "00:02:00",
  "Column5": "00:02:20",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:53",
  "Column4": "00:00:46",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:52",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:48",
  "Column4": "00:01:56",
  "Column5": "00:00:28",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:26",
  "Column4": "00:01:55",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:27",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:29",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:48",
  "Column4": "00:00:35",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "13-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:52",
  "Column4": "00:00:30",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:54",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:35",
  "Column4": "00:00:39",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:01:00",
  "Column5": "00:00:22",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:36",
  "Column4": "00:01:41",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:06",
  "Column4": "00:01:36",
  "Column5": "00:01:24",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:10",
  "Column4": "00:02:32",
  "Column5": "00:05:10",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:48",
  "Column4": "00:08:50",
  "Column5": "00:00:46",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:14",
  "Column4": "00:05:13",
  "Column5": "00:00:06",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "14-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:54",
  "Column4": "00:02:46",
  "Column5": "00:01:49",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:35",
  "Column4": "00:02:40",
  "Column5": "00:00:31",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:48",
  "Column4": "00:03:50",
  "Column5": "00:01:44",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:29",
  "Column4": "00:01:39",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:17",
  "Column5": "00:00:10",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:20",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:14",
  "Column4": "00:01:21",
  "Column5": "00:01:14",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:35",
  "Column4": "00:00:34",
  "Column5": "00:01:35",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:08",
  "Column4": "00:00:26",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "15-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:22",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:31",
  "Column4": "00:01:34",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:54",
  "Column4": "00:01:23",
  "Column5": "00:00:55",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:46",
  "Column4": "00:02:51",
  "Column5": "00:00:07",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:58",
  "Column4": "00:04:01",
  "Column5": "00:03:54",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:51",
  "Column5": "00:00:23",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:34",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:53",
  "Column4": "00:00:56",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:40",
  "Column4": "00:01:10",
  "Column5": "00:01:40",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "16-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:57",
  "Column4": "00:00:28",
  "Column5": "00:00:57",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:10",
  "Column4": "00:08:23",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:21",
  "Column4": "00:05:31",
  "Column5": "00:01:21",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:16",
  "Column4": "00:02:29",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:02",
  "Column4": "00:03:44",
  "Column5": "00:04:02",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:37",
  "Column4": "00:02:40",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:32",
  "Column4": "00:02:51",
  "Column5": "00:01:02",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:27",
  "Column4": "00:03:31",
  "Column5": "00:00:06",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:39",
  "Column4": "00:01:53",
  "Column5": "00:01:13",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "17-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:12",
  "Column4": "00:00:23",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:24",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:32",
  "Column4": "00:03:38",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:13",
  "Column4": "00:01:26",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:38",
  "Column4": "00:00:46",
  "Column5": "00:00:03",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:53",
  "Column4": "00:02:54",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "18-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:28",
  "Column4": "00:00:35",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:08:48",
  "Column4": "00:08:50",
  "Column5": "00:05:10",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Pagina 1 van 1",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Proprietary and Confidential, do not copy, duplicate or distribute",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 }
]