import PieChartTile from './PieChartTile';
import './UnityDash.scss';
import getPieData from '../../../functions/pieChartData';
import { chartTypesPieTile } from '../../../library/chartTypes';
import CustomTooltip from '../../Global/CustomTooltip';
import { useEffect, useState } from 'react';
import { allWeeks } from '../../../data/allWeeksObject';
import UserStatsTile from './UserStats/UserStatsTile';

const UnityDash = (props) => {

  const [activeWeek, setActiveWeek] = useState(30);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  });
  
  const user1Data = [[
    {name: 'Maandag', calls: 41},
    {name: 'Dinsdag', calls: 29},
    {name: 'Woensdag', calls: 0},
    {name: 'Donderdag', calls: 34},
    {name: 'Vrijdag', calls: 0},
    {name: 'Zaterdag', calls: 0}
  ], 
  [
    {name: 'Maandag', calls: 37},
    {name: 'Dinsdag', calls: 20},
    {name: 'Woensdag', calls: 0},
    {name: 'Donderdag', calls: 32},
    {name: 'Vrijdag', calls: 0},
    {name: 'Zaterdag', calls: 0}
  ], 
  [
    {name: 'Maandag', calls: 4},
    {name: 'Dinsdag', calls: 9},
    {name: 'Woensdag', calls: 0},
    {name: 'Donderdag', calls: 2},
    {name: 'Vrijdag', calls: 0},
    {name: 'Zaterdag', calls: 0}
  ], 
  [
    {name: 'Maandag', calls: 4},
    {name: 'Dinsdag', calls: 9},
    {name: 'Woensdag', calls: 0},
    {name: 'Donderdag', calls: 2},
    {name: 'Vrijdag', calls: 0},
    {name: 'Zaterdag', calls: 0}
  ]
  ]
  const user2Data = [[
    {name: 'Maandag', calls: 23},
    {name: 'Dinsdag', calls: 29},
    {name: 'Woensdag', calls: 24},
    {name: 'Donderdag', calls: 34},
    {name: 'Vrijdag', calls: 38},
    {name: 'Zaterdag', calls: 0}
  ], 
  [
    {name: 'Maandag', calls: 23},
    {name: 'Dinsdag', calls: 27},
    {name: 'Woensdag', calls: 22},
    {name: 'Donderdag', calls: 31},
    {name: 'Vrijdag', calls: 33},
    {name: 'Zaterdag', calls: 0}
  ], 
  [
    {name: 'Maandag', calls: 0},
    {name: 'Dinsdag', calls: 2},
    {name: 'Woensdag', calls: 2},
    {name: 'Donderdag', calls: 3},
    {name: 'Vrijdag', calls: 5},
    {name: 'Zaterdag', calls: 0}
  ], 
  [
    {name: 'Maandag', calls: 4},
    {name: 'Dinsdag', calls: 9},
    {name: 'Woensdag', calls: 0},
    {name: 'Donderdag', calls: 2},
    {name: 'Vrijdag', calls: 0},
    {name: 'Zaterdag', calls: 0}
  ]
  ]

  const onChangeActiveWeek = (event) => {
    setActiveWeek(event.target.value)
  }

  let weekSelectorJSX = allWeeks.map((week) => <option key={'test'+week.week} value={week.week}>{week.week}</option>);

  let tileJSX = chartTypesPieTile.map ((item) => {
    let data = getPieData(activeWeek, item.name);

    return (
      <div className='day-tile' key={'day-tile-' + item.key}>
        <div className='day-tile-title'>
          {item.title}
          <CustomTooltip description={item.description} />
        </div>
        <PieChartTile data={data} />
      </div>
    )
  });

  return (
    <div className='unity-dash'>
      <div className='unity-dash-title'>
        Overzicht Week {activeWeek}
        <div className='unity-dash-week-selector'>
          Kies week:&nbsp;
          <select name='week-active' onChange={onChangeActiveWeek}>
            {weekSelectorJSX}
          </select>
        </div>
      </div>
      <div className='unity-dash-day-tiles'>
        {tileJSX}
      </div>
      <div className='unity-dash-main-container'>
        <div className='unity-dash-main-item'>
          <UserStatsTile data={user1Data} username='Gebruiker 1' />
        </div>
        <div className='unity-dash-main-item'>
          TEST2
        </div>
        <div className='unity-dash-main-item'>
          <UserStatsTile data={user2Data} username='Gebruiker 2' />
        </div>
        <div className='unity-dash-main-item'>
          TEST4
        </div>
        <div className='unity-dash-main-item'>
          <UserStatsTile data={user1Data} username='Gebruiker 3' />
        </div>
        <div className='unity-dash-main-item'>
          TEST6
        </div>
        <div className='unity-dash-main-item'>
          <UserStatsTile data={user2Data} username='Gebruiker 4' />
        </div>
        <div className='unity-dash-main-item'>
          TEST8
        </div>
      </div>
    </div>
  )
}

export default UnityDash;