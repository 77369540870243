import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as ImIcons from 'react-icons/im';

import Home from '../Home/Home';
import UnityDash from '../Unity/Dash/UnityDash';
import AveragePerHour from '../Unity/AveragePerHour/AveragePerHour';
import TestComponent from '../Unity/TestComponent/TestComponent';
import PageNotFound from '../PageNotFound';

let JSX404 = <PageNotFound />

export const sidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'sidebar-main-link',
    element: <Home />,
  },
  {
    title: 'Analyse Unity',
    path: '/unity/dash',
    icon: <BsIcons.BsFillTelephoneFill />,
    cName: 'sidebar-category',
    element: <UnityDash />,
  },
  {
    title: 'Gemiddeld Per Uur',
    path: '/unity/avg-per-hour',
    icon: <BsIcons.BsDot />,
    cName: 'sidebar-child-link',
    element: <AveragePerHour />,
  },
  {
    title: 'Test',
    path: '/unity/test',
    icon: <BsIcons.BsDot />,
    cName: 'sidebar-child-link',
    element: <TestComponent />,
  },
  {
    title: 'Analyse Manco\'s',
    path: '/manco/dash',
    icon: <ImIcons.ImCross />,
    cName: 'sidebar-category',
    element: JSX404,
  },
  {
    title: 'Manco\'s per week',
    path: '/manco/weekly',
    icon: <BsIcons.BsDot />,
    cName: 'sidebar-child-link',
    element: JSX404,
  }
]