export const reportWeek38 = [
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Start Time:",
  "Column2": "19-09-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "End Time:",
  "Column2": "26-09-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Time Zone:",
  "Column2": "(GMT+02:00) Europe\/Amsterdam",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date Run:",
  "Column2": "26-09-2021, 23:07",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Name:",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Service Id:",
  "Column2": "Klantenservice-NijBeu-CC@huuskes.yestelecom.nl",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Activity",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Calls Queued",
  "Column4": "Calls Escaped",
  "Column5": "Calls Abandoned",
  "Column6": "Calls Presented",
  "Column7": "Calls Answered",
  "Column8": "Calls Answered In 30 secs",
  "Column9": "% Calls Answered In 30 secs",
  "Column10": "% Answered Calls Answered In 30 secs",
  "Column11": "Calls Overflowed - Time",
  "Column12": "Calls Bounced",
  "Column13": "Calls Bounced- Transferred",
  "Column14": "Calls Transferred",
  "Column15": "Calls Stranded",
  "Column16": "Calls Stranded-Unavailable"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "1",
  "Column6": "26",
  "Column7": "19",
  "Column8": "16",
  "Column9": "84.2%",
  "Column10": "84.2%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "0",
  "Column6": "33",
  "Column7": "29",
  "Column8": "23",
  "Column9": "79.3%",
  "Column10": "79.3%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "35",
  "Column4": "0",
  "Column5": "3",
  "Column6": "40",
  "Column7": "31",
  "Column8": "22",
  "Column9": "71.0%",
  "Column10": "71.0%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "47",
  "Column4": "0",
  "Column5": "3",
  "Column6": "63",
  "Column7": "42",
  "Column8": "25",
  "Column9": "59.5%",
  "Column10": "59.5%",
  "Column11": "0",
  "Column12": "17",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "2",
  "Column6": "29",
  "Column7": "19",
  "Column8": "13",
  "Column9": "68.4%",
  "Column10": "68.4%",
  "Column11": "0",
  "Column12": "10",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "25",
  "Column4": "0",
  "Column5": "2",
  "Column6": "38",
  "Column7": "22",
  "Column8": "12",
  "Column9": "54.5%",
  "Column10": "54.5%",
  "Column11": "0",
  "Column12": "14",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "1",
  "Column6": "24",
  "Column7": "18",
  "Column8": "10",
  "Column9": "55.6%",
  "Column10": "55.6%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "2",
  "Column6": "17",
  "Column7": "11",
  "Column8": "8",
  "Column9": "72.7%",
  "Column10": "72.7%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "2",
  "Column4": "0",
  "Column5": "0",
  "Column6": "2",
  "Column7": "2",
  "Column8": "2",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "0",
  "Column6": "13",
  "Column7": "13",
  "Column8": "11",
  "Column9": "84.6%",
  "Column10": "84.6%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "19",
  "Column4": "0",
  "Column5": "1",
  "Column6": "26",
  "Column7": "17",
  "Column8": "12",
  "Column9": "70.6%",
  "Column10": "70.6%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "0",
  "Column6": "38",
  "Column7": "30",
  "Column8": "15",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "29",
  "Column4": "0",
  "Column5": "2",
  "Column6": "35",
  "Column7": "27",
  "Column8": "19",
  "Column9": "70.4%",
  "Column10": "70.4%",
  "Column11": "0",
  "Column12": "8",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "1",
  "Column6": "17",
  "Column7": "14",
  "Column8": "10",
  "Column9": "71.4%",
  "Column10": "71.4%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "11",
  "Column4": "0",
  "Column5": "1",
  "Column6": "14",
  "Column7": "10",
  "Column8": "4",
  "Column9": "40.0%",
  "Column10": "40.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "22",
  "Column4": "0",
  "Column5": "1",
  "Column6": "25",
  "Column7": "17",
  "Column8": "4",
  "Column9": "23.5%",
  "Column10": "23.5%",
  "Column11": "0",
  "Column12": "8",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "3",
  "Column6": "24",
  "Column7": "14",
  "Column8": "8",
  "Column9": "57.1%",
  "Column10": "57.1%",
  "Column11": "0",
  "Column12": "8",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "6",
  "Column4": "0",
  "Column5": "0",
  "Column6": "6",
  "Column7": "5",
  "Column8": "4",
  "Column9": "80.0%",
  "Column10": "80.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 17:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "0",
  "Column4": "0",
  "Column5": "1",
  "Column6": "1",
  "Column7": "0",
  "Column8": "0",
  "Column9": "0.0%",
  "Column10": "0.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "3",
  "Column6": "23",
  "Column7": "9",
  "Column8": "2",
  "Column9": "22.2%",
  "Column10": "22.2%",
  "Column11": "0",
  "Column12": "14",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "2",
  "Column6": "20",
  "Column7": "17",
  "Column8": "13",
  "Column9": "76.5%",
  "Column10": "76.5%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "0",
  "Column6": "21",
  "Column7": "20",
  "Column8": "17",
  "Column9": "85.0%",
  "Column10": "85.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "23",
  "Column4": "0",
  "Column5": "2",
  "Column6": "28",
  "Column7": "21",
  "Column8": "16",
  "Column9": "76.2%",
  "Column10": "76.2%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "1",
  "Column6": "18",
  "Column7": "16",
  "Column8": "11",
  "Column9": "68.8%",
  "Column10": "68.8%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "23",
  "Column4": "0",
  "Column5": "2",
  "Column6": "27",
  "Column7": "20",
  "Column8": "13",
  "Column9": "65.0%",
  "Column10": "65.0%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "2",
  "Column6": "15",
  "Column7": "12",
  "Column8": "9",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "1",
  "Column6": "15",
  "Column7": "12",
  "Column8": "11",
  "Column9": "91.7%",
  "Column10": "91.7%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "2",
  "Column4": "0",
  "Column5": "0",
  "Column6": "3",
  "Column7": "2",
  "Column8": "1",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "11",
  "Column4": "0",
  "Column5": "0",
  "Column6": "11",
  "Column7": "11",
  "Column8": "10",
  "Column9": "90.9%",
  "Column10": "90.9%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "19",
  "Column4": "0",
  "Column5": "0",
  "Column6": "28",
  "Column7": "18",
  "Column8": "17",
  "Column9": "94.4%",
  "Column10": "94.4%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "23",
  "Column4": "0",
  "Column5": "0",
  "Column6": "32",
  "Column7": "24",
  "Column8": "20",
  "Column9": "83.3%",
  "Column10": "83.3%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "1",
  "Column6": "25",
  "Column7": "20",
  "Column8": "17",
  "Column9": "85.0%",
  "Column10": "85.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "2",
  "Column6": "23",
  "Column7": "12",
  "Column8": "5",
  "Column9": "41.7%",
  "Column10": "41.7%",
  "Column11": "0",
  "Column12": "10",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "1",
  "Column6": "21",
  "Column7": "13",
  "Column8": "9",
  "Column9": "69.2%",
  "Column10": "69.2%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "22",
  "Column4": "0",
  "Column5": "2",
  "Column6": "22",
  "Column7": "20",
  "Column8": "14",
  "Column9": "70.0%",
  "Column10": "70.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "3",
  "Column4": "0",
  "Column5": "0",
  "Column6": "5",
  "Column7": "3",
  "Column8": "1",
  "Column9": "33.3%",
  "Column10": "33.3%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "6",
  "Column4": "0",
  "Column5": "1",
  "Column6": "6",
  "Column7": "4",
  "Column8": "4",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "1",
  "Column6": "18",
  "Column7": "15",
  "Column8": "6",
  "Column9": "40.0%",
  "Column10": "40.0%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "23",
  "Column4": "0",
  "Column5": "3",
  "Column6": "25",
  "Column7": "20",
  "Column8": "12",
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "23",
  "Column4": "0",
  "Column5": "0",
  "Column6": "28",
  "Column7": "22",
  "Column8": "17",
  "Column9": "77.3%",
  "Column10": "77.3%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "32",
  "Column4": "0",
  "Column5": "3",
  "Column6": "43",
  "Column7": "29",
  "Column8": "16",
  "Column9": "55.2%",
  "Column10": "55.2%",
  "Column11": "0",
  "Column12": "12",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "0",
  "Column6": "24",
  "Column7": "14",
  "Column8": "10",
  "Column9": "71.4%",
  "Column10": "71.4%",
  "Column11": "0",
  "Column12": "10",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "12",
  "Column4": "0",
  "Column5": "1",
  "Column6": "12",
  "Column7": "10",
  "Column8": "9",
  "Column9": "90.0%",
  "Column10": "90.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "5",
  "Column6": "15",
  "Column7": "10",
  "Column8": "6",
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "4",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "3",
  "Column6": "14",
  "Column7": "9",
  "Column8": "6",
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "6",
  "Column4": "0",
  "Column5": "0",
  "Column6": "6",
  "Column7": "6",
  "Column8": "6",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "5",
  "Column4": "0",
  "Column5": "0",
  "Column6": "5",
  "Column7": "5",
  "Column8": "4",
  "Column9": "80.0%",
  "Column10": "80.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "9",
  "Column4": "0",
  "Column5": "0",
  "Column6": "8",
  "Column7": "8",
  "Column8": "6",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "1",
  "Column6": "14",
  "Column7": "12",
  "Column8": "8",
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "3",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "19",
  "Column4": "0",
  "Column5": "0",
  "Column6": "19",
  "Column7": "18",
  "Column8": "9",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "9",
  "Column4": "0",
  "Column5": "1",
  "Column6": "9",
  "Column7": "6",
  "Column8": "5",
  "Column9": "83.3%",
  "Column10": "83.3%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "4",
  "Column4": "0",
  "Column5": "0",
  "Column6": "4",
  "Column7": "4",
  "Column8": "3",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "874",
  "Column4": "0",
  "Column5": "62",
  "Column6": "1058",
  "Column7": "782",
  "Column8": "531",
  "Column9": "67.9%",
  "Column10": "67.9%",
  "Column11": "0",
  "Column12": "243",
  "Column13": "0",
  "Column14": "30",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "High Water Marks",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Longest Wait Time",
  "Column4": "Longest Answer Time",
  "Column5": "Longest Wait Abandoned",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:40",
  "Column4": "00:01:43",
  "Column5": "00:00:36",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:19",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:04",
  "Column4": "00:01:07",
  "Column5": "00:00:34",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:38",
  "Column4": "00:01:40",
  "Column5": "00:01:19",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:47",
  "Column4": "00:02:51",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:33",
  "Column4": "00:02:54",
  "Column5": "00:00:37",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:37",
  "Column4": "00:02:54",
  "Column5": "00:00:14",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:16",
  "Column4": "00:03:25",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "20-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:15",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:07",
  "Column4": "00:03:12",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:36",
  "Column4": "00:01:42",
  "Column5": "00:01:32",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:46",
  "Column4": "00:01:54",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:38",
  "Column4": "00:01:39",
  "Column5": "00:00:33",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:41",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:47",
  "Column4": "00:02:58",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:49",
  "Column4": "00:04:52",
  "Column5": "00:01:19",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:15",
  "Column4": "00:02:29",
  "Column5": "00:02:15",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:16",
  "Column4": "00:00:36",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "21-09-2021, 17:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:50",
  "Column4": "00:00:00",
  "Column5": "00:00:50",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:48",
  "Column4": "00:04:50",
  "Column5": "00:00:06",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:23",
  "Column4": "00:02:28",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:01",
  "Column4": "00:00:54",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:58",
  "Column4": "00:03:04",
  "Column5": "00:00:12",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:43",
  "Column5": "00:00:03",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:10",
  "Column4": "00:02:17",
  "Column5": "00:00:14",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:13",
  "Column4": "00:00:45",
  "Column5": "00:00:11",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:11",
  "Column4": "00:01:28",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "22-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:55",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:36",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:13",
  "Column4": "00:00:31",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:09",
  "Column4": "00:01:20",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:54",
  "Column5": "00:00:31",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:48",
  "Column4": "00:01:52",
  "Column5": "00:00:03",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:38",
  "Column4": "00:01:57",
  "Column5": "00:00:36",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:38",
  "Column4": "00:01:54",
  "Column5": "00:00:21",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:01:03",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "23-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:00",
  "Column4": "00:00:14",
  "Column5": "00:00:18",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:07:51",
  "Column4": "00:07:59",
  "Column5": "00:00:30",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:00",
  "Column4": "00:02:02",
  "Column5": "00:00:22",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:09",
  "Column4": "00:01:15",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:57",
  "Column4": "00:01:10",
  "Column5": "00:01:57",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:21",
  "Column4": "00:01:48",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:12",
  "Column4": "00:00:31",
  "Column5": "00:00:10",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:43",
  "Column4": "00:04:02",
  "Column5": "00:03:02",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:34",
  "Column4": "00:01:38",
  "Column5": "00:00:30",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "24-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:28",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:44",
  "Column4": "00:01:04",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:49",
  "Column4": "00:00:50",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:07",
  "Column4": "00:00:59",
  "Column5": "00:00:21",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:31",
  "Column4": "00:02:49",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:44",
  "Column4": "00:01:47",
  "Column5": "00:01:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "25-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:26",
  "Column4": "00:01:27",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:07:51",
  "Column4": "00:07:59",
  "Column5": "00:03:02",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Pagina 1 van 1",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Proprietary and Confidential, do not copy, duplicate or distribute",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 }
]