export const chartTypesLineMain = [{
  name: 'queued',
  title: 'Inkomend',
  key: 'graph-type-0',
  settings: {
    title: 'BINNENKOMENDE LIJNTJES',
    domain: [0, 40],
    description: 'Het gemiddelde aantal inkomende gesprekken per uur in de gekozen periode.',
    percentage: false,
  },
  column: 'Column3',
  colName: 'Calls Queued',
}, {
  name: 'answered',
  title: 'Beantwoord',
  key: 'graph-type-1',
  settings: {
    title: 'BEANTWOORD',
    domain: [0, 40],
    description: 'Het gemiddelde aantal beantwoorde gesprekken per uur in de gekozen periode.',
    percentage: false,
  },
  column: 'Column7',
  colName: 'Calls Answered',
}, {
  name: 'abandoned',
  title: 'Onbeantwoord',
  key: 'graph-type-2',
  settings: {
    title: 'ONBEANTWOORD',
    domain: ['auto', 12],
    description: 'Het gemiddelde aantal onbeantwoorde gesprekken per uur in de gekozen periode.',
    percentage: false,
  },
  column: 'Column5',
  colName: 'Calls Abandoned',
},{
  name: 'bounced',
  title: 'Doorgeschakeld',
  key: 'graph-type-3',
  settings: {
    title: 'DOORGESTUURD',
    domain: ['auto', 16],
    description: 'Het gemiddelde aantal doorgeschakelde gesprekken per uur in de gekozen periode. Niet opgenomen door de oorspronkelijke klantenservice medewerker, en dus doorgeschakeld naar een ander. Deze telefoontjes zijn uiteindelijk dus wel opgenomen.',
    percentage: false,
  },
  column: 'Column12',
  colName: 'Calls Bounced',
}, {
  name: 'answeredin30',
  title: 'Beantwoord binnen 30 seconden',
  key: 'graph-type-4',
  settings: {
    title: 'BEANTWOORD BINNEN 30 SECONDEN',
    domain: [0, 100],
    description: 'Het gemiddelde percentage gesprekken wat binnen 30 seconden is opgenomen.',
    percentage: true,
  },
  column: 'Column9',
  colName: '% Calls Answered In 30 secs',
}
];

export const chartTypesPieTile = [{
  name: 'queued',
  title: 'Inkomend',
  key: 'graph-type-0',
  column: 'Column3',
  colName: 'Calls Queued',
  description: 'Het totaal aantal inkomende gesprekken per dag.'
}, {
  name: 'answered',
  title: 'Beantwoord',
  key: 'graph-type-1',
  column: 'Column7',
  colName: 'Calls Answered',
  description: 'Het totaal aantal beantwoorde gesprekken per dag.'
}, {
  name: 'abandoned',
  title: 'Onbeantwoord',
  key: 'graph-type-2',
  column: 'Column5',
  colName: 'Calls Abandoned',
  description: 'Het totaal aantal onbeantwoorde gesprekken per dag.'
},{
  name: 'bounced',
  title: 'Doorgeschakeld',
  key: 'graph-type-3',
  column: 'Column12',
  colName: 'Calls Bounced',
  description: 'Het totaal aantal doorgeschakelde gesprekken per dag.'
}]