import { useLocation, Link } from 'react-router-dom';

import { sidebarData } from './sidebarData';

import './SideBar.scss';



const SideBar = (props) => {
  const currentLocation = useLocation();

  let JSX = sidebarData.map((item, index) => <Link key={'link-' + index} to={item.path} id={currentLocation.pathname === item.path ? 'active' : ''} className={item.cName}>{item.icon}&nbsp;{item.title}</Link>)

  return (
      <nav className='sidebar'>
        {JSX}
      </nav>
  )
}

export default SideBar;