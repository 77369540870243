import * as BsIcons from 'react-icons/bs';
import { IconContext } from 'react-icons/lib';

import './CustomTooltip.scss';

const CustomTooltip = (props) => {
  

  return (
    <div className='graph-tooltip'>
      <IconContext.Provider value={{size: '0.8rem'}}>
        <BsIcons.BsQuestionCircleFill />
      </IconContext.Provider>
      <p className='graph-tooltip-text'>{props.description}</p>
    </div>
  )
}

export default CustomTooltip;