import { Cell, Pie, PieChart, Tooltip } from "recharts";

import { days } from "../../../library/days";

import './PieChartTile.scss';

const PieChartTile = (props) => {

  const colors = days.map((day) => day.color);
  const reverseColors = colors.reverse();

  const graphSize = (window.innerWidth / 100) * 10;

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'middle' : 'middle'} dominantBaseline="central">
        {value}
      </text>
    );
  };

  return (
    <PieChart className='pie-chart' width={graphSize} height={graphSize} >
      <Pie  data={props.data} 
            dataKey='value'
            cx='50%' 
            cy='50%' 
            outerRadius={graphSize/2.1} 
            labelLine={false}
            label={renderCustomizedLabel}
            isAnimationActive={false}
            startAngle={90}
            endAngle={450}>
      { 
        props.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={reverseColors[index]} />
        ))
      }
      </Pie>
      <Tooltip contentStyle={{background: 'rgb(170, 189, 191, 0.5)', borderRadius: '15px'}} allowEscapeViewBox={{x: true, y: true}} />
    </PieChart>
  )
}

export default PieChartTile;