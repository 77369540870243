export const reportWeek39 = [
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Start Time:",
  "Column2": "26-09-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "End Time:",
  "Column2": "03-10-2021, 00:00",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Time Zone:",
  "Column2": "(GMT+02:00) Europe\/Amsterdam",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date Run:",
  "Column2": "03-10-2021, 23:07",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Name:",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Service Id:",
  "Column2": "Klantenservice-NijBeu-CC@huuskes.yestelecom.nl",
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Call Center Activity",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Calls Queued",
  "Column4": "Calls Escaped",
  "Column5": "Calls Abandoned",
  "Column6": "Calls Presented",
  "Column7": "Calls Answered",
  "Column8": "Calls Answered In 30 secs",
  "Column9": "% Calls Answered In 30 secs",
  "Column10": "% Answered Calls Answered In 30 secs",
  "Column11": "Calls Overflowed - Time",
  "Column12": "Calls Bounced",
  "Column13": "Calls Bounced- Transferred",
  "Column14": "Calls Transferred",
  "Column15": "Calls Stranded",
  "Column16": "Calls Stranded-Unavailable"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "19",
  "Column4": "0",
  "Column5": "1",
  "Column6": "21",
  "Column7": "18",
  "Column8": "10",
  "Column9": "55.6%",
  "Column10": "55.6%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "1",
  "Column6": "26",
  "Column7": "20",
  "Column8": "15",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "1",
  "Column6": "19",
  "Column7": "17",
  "Column8": "15",
  "Column9": "88.2%",
  "Column10": "88.2%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "22",
  "Column4": "0",
  "Column5": "2",
  "Column6": "24",
  "Column7": "20",
  "Column8": "7",
  "Column9": "35.0%",
  "Column10": "35.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "31",
  "Column4": "0",
  "Column5": "4",
  "Column6": "34",
  "Column7": "21",
  "Column8": "10",
  "Column9": "47.6%",
  "Column10": "47.6%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "2",
  "Column6": "23",
  "Column7": "16",
  "Column8": "8",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "1",
  "Column6": "26",
  "Column7": "20",
  "Column8": "16",
  "Column9": "80.0%",
  "Column10": "80.0%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "8",
  "Column4": "0",
  "Column5": "2",
  "Column6": "12",
  "Column7": "6",
  "Column8": "3",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "6",
  "Column4": "0",
  "Column5": "2",
  "Column6": "6",
  "Column7": "4",
  "Column8": "2",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "0",
  "Column6": "20",
  "Column7": "19",
  "Column8": "13",
  "Column9": "68.4%",
  "Column10": "68.4%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "0",
  "Column6": "22",
  "Column7": "18",
  "Column8": "14",
  "Column9": "77.8%",
  "Column10": "77.8%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "27",
  "Column4": "0",
  "Column5": "1",
  "Column6": "34",
  "Column7": "25",
  "Column8": "16",
  "Column9": "64.0%",
  "Column10": "64.0%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "31",
  "Column4": "0",
  "Column5": "0",
  "Column6": "40",
  "Column7": "31",
  "Column8": "20",
  "Column9": "64.5%",
  "Column10": "64.5%",
  "Column11": "0",
  "Column12": "9",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "0",
  "Column6": "24",
  "Column7": "17",
  "Column8": "7",
  "Column9": "41.2%",
  "Column10": "41.2%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "0",
  "Column6": "15",
  "Column7": "14",
  "Column8": "11",
  "Column9": "78.6%",
  "Column10": "78.6%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "22",
  "Column4": "0",
  "Column5": "2",
  "Column6": "28",
  "Column7": "20",
  "Column8": "15",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "8",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "2",
  "Column6": "18",
  "Column7": "16",
  "Column8": "11",
  "Column9": "68.8%",
  "Column10": "68.8%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "3",
  "Column4": "0",
  "Column5": "0",
  "Column6": "3",
  "Column7": "3",
  "Column8": "3",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "0",
  "Column6": "26",
  "Column7": "20",
  "Column8": "13",
  "Column9": "65.0%",
  "Column10": "65.0%",
  "Column11": "0",
  "Column12": "5",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "26",
  "Column4": "0",
  "Column5": "2",
  "Column6": "25",
  "Column7": "24",
  "Column8": "19",
  "Column9": "79.2%",
  "Column10": "79.2%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "24",
  "Column4": "0",
  "Column5": "1",
  "Column6": "31",
  "Column7": "23",
  "Column8": "17",
  "Column9": "73.9%",
  "Column10": "73.9%",
  "Column11": "0",
  "Column12": "7",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "27",
  "Column4": "0",
  "Column5": "4",
  "Column6": "31",
  "Column7": "22",
  "Column8": "16",
  "Column9": "72.7%",
  "Column10": "72.7%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "2",
  "Column6": "31",
  "Column7": "17",
  "Column8": "9",
  "Column9": "52.9%",
  "Column10": "52.9%",
  "Column11": "0",
  "Column12": "13",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "0",
  "Column6": "16",
  "Column7": "13",
  "Column8": "12",
  "Column9": "92.3%",
  "Column10": "92.3%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "1",
  "Column6": "13",
  "Column7": "12",
  "Column8": "11",
  "Column9": "91.7%",
  "Column10": "91.7%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "10",
  "Column4": "0",
  "Column5": "2",
  "Column6": "10",
  "Column7": "8",
  "Column8": "4",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "14",
  "Column4": "0",
  "Column5": "0",
  "Column6": "14",
  "Column7": "14",
  "Column8": "9",
  "Column9": "64.3%",
  "Column10": "64.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "1",
  "Column6": "18",
  "Column7": "14",
  "Column8": "12",
  "Column9": "85.7%",
  "Column10": "85.7%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "16",
  "Column4": "0",
  "Column5": "0",
  "Column6": "19",
  "Column7": "15",
  "Column8": "9",
  "Column9": "60.0%",
  "Column10": "60.0%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "18",
  "Column4": "0",
  "Column5": "0",
  "Column6": "20",
  "Column7": "17",
  "Column8": "15",
  "Column9": "88.2%",
  "Column10": "88.2%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "2",
  "Column6": "19",
  "Column7": "15",
  "Column8": "13",
  "Column9": "86.7%",
  "Column10": "86.7%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "11",
  "Column4": "0",
  "Column5": "0",
  "Column6": "14",
  "Column7": "9",
  "Column8": "8",
  "Column9": "88.9%",
  "Column10": "88.9%",
  "Column11": "0",
  "Column12": "4",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "21",
  "Column4": "0",
  "Column5": "0",
  "Column6": "24",
  "Column7": "20",
  "Column8": "13",
  "Column9": "65.0%",
  "Column10": "65.0%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "13",
  "Column4": "0",
  "Column5": "3",
  "Column6": "10",
  "Column7": "8",
  "Column8": "4",
  "Column9": "50.0%",
  "Column10": "50.0%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "2",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "12",
  "Column4": "0",
  "Column5": "1",
  "Column6": "12",
  "Column7": "11",
  "Column8": "7",
  "Column9": "63.6%",
  "Column10": "63.6%",
  "Column11": "0",
  "Column12": "1",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "7",
  "Column4": "0",
  "Column5": "1",
  "Column6": "7",
  "Column7": "6",
  "Column8": "5",
  "Column9": "83.3%",
  "Column10": "83.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "20",
  "Column4": "0",
  "Column5": "1",
  "Column6": "22",
  "Column7": "19",
  "Column8": "9",
  "Column9": "47.4%",
  "Column10": "47.4%",
  "Column11": "0",
  "Column12": "3",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "35",
  "Column4": "0",
  "Column5": "8",
  "Column6": "46",
  "Column7": "27",
  "Column8": "18",
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": "0",
  "Column12": "17",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "30",
  "Column4": "0",
  "Column5": "4",
  "Column6": "31",
  "Column7": "24",
  "Column8": "8",
  "Column9": "33.3%",
  "Column10": "33.3%",
  "Column11": "0",
  "Column12": "6",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "41",
  "Column4": "0",
  "Column5": "4",
  "Column6": "60",
  "Column7": "38",
  "Column8": "13",
  "Column9": "34.2%",
  "Column10": "34.2%",
  "Column11": "0",
  "Column12": "21",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "22",
  "Column4": "0",
  "Column5": "1",
  "Column6": "23",
  "Column7": "20",
  "Column8": "18",
  "Column9": "90.0%",
  "Column10": "90.0%",
  "Column11": "0",
  "Column12": "2",
  "Column13": "0",
  "Column14": "1",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "31",
  "Column4": "0",
  "Column5": "4",
  "Column6": "40",
  "Column7": "25",
  "Column8": "8",
  "Column9": "32.0%",
  "Column10": "32.0%",
  "Column11": "0",
  "Column12": "14",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "33",
  "Column4": "0",
  "Column5": "9",
  "Column6": "42",
  "Column7": "23",
  "Column8": "4",
  "Column9": "17.4%",
  "Column10": "17.4%",
  "Column11": "0",
  "Column12": "17",
  "Column13": "0",
  "Column14": "3",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "2",
  "Column6": "15",
  "Column7": "15",
  "Column8": "5",
  "Column9": "33.3%",
  "Column10": "33.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "3",
  "Column4": "0",
  "Column5": "1",
  "Column6": "3",
  "Column7": "2",
  "Column8": "2",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "6",
  "Column4": "0",
  "Column5": "0",
  "Column6": "6",
  "Column7": "6",
  "Column8": "6",
  "Column9": "100.0%",
  "Column10": "100.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "15",
  "Column4": "0",
  "Column5": "0",
  "Column6": "15",
  "Column7": "15",
  "Column8": "8",
  "Column9": "53.3%",
  "Column10": "53.3%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "8",
  "Column4": "0",
  "Column5": "0",
  "Column6": "8",
  "Column7": "8",
  "Column8": "7",
  "Column9": "87.5%",
  "Column10": "87.5%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "17",
  "Column4": "0",
  "Column5": "1",
  "Column6": "16",
  "Column7": "16",
  "Column8": "12",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "4",
  "Column4": "0",
  "Column5": "0",
  "Column6": "4",
  "Column7": "4",
  "Column8": "3",
  "Column9": "75.0%",
  "Column10": "75.0%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "3",
  "Column4": "0",
  "Column5": "0",
  "Column6": "3",
  "Column7": "3",
  "Column8": "2",
  "Column9": "66.7%",
  "Column10": "66.7%",
  "Column11": "0",
  "Column12": "0",
  "Column13": "0",
  "Column14": "0",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "914",
  "Column4": "0",
  "Column5": "76",
  "Column6": "1069",
  "Column7": "818",
  "Column8": "515",
  "Column9": "63.0%",
  "Column10": "63.0%",
  "Column11": "0",
  "Column12": "215",
  "Column13": "0",
  "Column14": "20",
  "Column15": "0",
  "Column16": "0"
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "High Water Marks",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Date and Time",
  "Column2": "Call Center Name",
  "Column3": "Longest Wait Time",
  "Column4": "Longest Answer Time",
  "Column5": "Longest Wait Abandoned",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:54",
  "Column4": "00:03:57",
  "Column5": "00:01:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:20",
  "Column4": "00:05:22",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:58",
  "Column4": "00:00:36",
  "Column5": "00:00:58",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:55",
  "Column4": "00:05:03",
  "Column5": "00:00:28",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:33",
  "Column4": "00:03:05",
  "Column5": "00:02:13",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:14:39",
  "Column4": "00:14:47",
  "Column5": "00:05:16",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:35",
  "Column4": "00:01:39",
  "Column5": "00:00:10",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:52",
  "Column4": "00:00:51",
  "Column5": "00:01:52",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "27-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:08",
  "Column4": "00:00:41",
  "Column5": "00:01:08",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:53",
  "Column4": "00:01:10",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:27",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:35",
  "Column4": "00:01:37",
  "Column5": "00:00:09",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:13",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:06",
  "Column4": "00:01:14",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:00",
  "Column4": "00:03:05",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:39",
  "Column4": "00:04:30",
  "Column5": "00:04:39",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:19",
  "Column4": "00:01:23",
  "Column5": "00:00:05",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "28-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:26",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:22",
  "Column4": "00:02:27",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:58",
  "Column4": "00:04:00",
  "Column5": "00:00:58",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:14",
  "Column4": "00:01:21",
  "Column5": "00:01:14",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:11",
  "Column4": "00:03:33",
  "Column5": "00:02:19",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:25",
  "Column4": "00:03:53",
  "Column5": "00:01:36",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:41",
  "Column4": "00:00:43",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:49",
  "Column4": "00:00:38",
  "Column5": "00:00:49",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:28",
  "Column4": "00:02:35",
  "Column5": "00:00:20",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "29-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:51",
  "Column4": "00:03:54",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:36",
  "Column4": "00:00:43",
  "Column5": "00:00:01",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:10",
  "Column4": "00:04:23",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:51",
  "Column4": "00:01:02",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:58",
  "Column4": "00:00:47",
  "Column5": "00:00:58",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:14",
  "Column4": "00:00:44",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:34",
  "Column4": "00:03:36",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:14",
  "Column4": "00:02:17",
  "Column5": "00:00:03",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:05",
  "Column4": "00:05:07",
  "Column5": "00:03:48",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "30-09-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:39",
  "Column4": "00:01:41",
  "Column5": "00:00:25",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:38",
  "Column4": "00:02:40",
  "Column5": "00:00:21",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:44",
  "Column4": "00:02:48",
  "Column5": "00:01:37",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:49",
  "Column4": "00:05:50",
  "Column5": "00:02:57",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:06:54",
  "Column4": "00:07:18",
  "Column5": "00:03:41",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:01:31",
  "Column4": "00:01:42",
  "Column5": "00:00:04",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:04:49",
  "Column4": "00:04:53",
  "Column5": "00:01:28",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 14:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:11:38",
  "Column4": "00:11:40",
  "Column5": "00:05:35",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 15:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:05:39",
  "Column4": "00:05:42",
  "Column5": "00:00:08",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "01-10-2021, 16:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:19",
  "Column5": "00:00:06",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 08:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:00:06",
  "Column4": "00:00:14",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 09:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:40",
  "Column4": "00:02:44",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 10:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:50",
  "Column4": "00:02:51",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 11:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:03:40",
  "Column4": "00:03:42",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 12:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:45",
  "Column4": "00:02:47",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "02-10-2021, 13:00",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:02:54",
  "Column4": "00:02:56",
  "Column5": "00:00:00",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Report Summary",
  "Column2": "Klantenservice Nijkerk Beuning",
  "Column3": "00:14:39",
  "Column4": "00:14:47",
  "Column5": "00:05:35",
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": null,
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Pagina 1 van 1",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 },
 {
  "Call Center Report - Klantenservice Nijkerk Beuning - Hourly": "Proprietary and Confidential, do not copy, duplicate or distribute",
  "Column2": null,
  "Column3": null,
  "Column4": null,
  "Column5": null,
  "Column6": null,
  "Column7": null,
  "Column8": null,
  "Column9": null,
  "Column10": null,
  "Column11": null,
  "Column12": null,
  "Column13": null,
  "Column14": null,
  "Column15": null,
  "Column16": null
 }
]