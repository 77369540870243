function TestComponent() {

  return ( 
    <div className='test'> 
      <h1>TEST</h1>
      <p> TEST TEST </p>
      <h2> TEST </h2>
    </div> 
  );
}

export default TestComponent;