import './Home.scss';
import Welcome from './Welcome';

const Home = (props) => {


  return(
    <div className='home'>
      <Welcome />
    </div>
  )
}

export default Home;