import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { sidebarData } from './Components/SideBar/sidebarData';

import SideBar from './Components/SideBar/SideBar';
import TopBar from './Components/TopBar/TopBar';

import PageNotFound from './Components/PageNotFound';

import { IconContext } from 'react-icons/lib';
import { MdShowChart } from 'react-icons/md';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import './App.scss';

let routeJSX = sidebarData.map((item, index) => <Route key={'route-' + index} path={item.path} element={item.element} />)

function App() {

  return ( 
    <div className='app'> 
        <BrowserRouter>
          <Helmet>
            <title>Dashboard</title>
          </Helmet>
          <Link id='header' to='/'>
            <IconContext.Provider value={{size: '2.5em'}}>
              <MdShowChart />
              <p>
                DASHBOARD 
              </p>
            </IconContext.Provider>
          </Link>
          <TopBar id='topbar' />
          <SideBar id='sidebar' />

            <Routes>
              {routeJSX}
              <Route path='*' element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    </div> 
  );
}


export default App;  
