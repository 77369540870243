import { useEffect } from 'react';

import './GraphTypeSelector.scss';

import { chartTypesLineMain } from '../../../../library/chartTypes';

const GraphTypeSelector = (props) => {

  //TODO Figure out how to do this button stuff without document.getEl......

  useEffect(() => {
    let activeButton = document.getElementById(props.active);

    activeButton.classList.add('active');
  })

  const onChangeGraphType = (element) => {
    let activeElements = document.getElementsByClassName('active');
    for (let i = 0; i < activeElements.length; i++) {
      activeElements[i].classList.remove('active');
    }
    props.onChangeGraphType(element.target.id);
  }

  let JSX = chartTypesLineMain.map((e) => 
    <button key={e.key} id={e.name} class={'graph-selector'} onClick={onChangeGraphType} >{e.title}</button>
  )

  return (
    <div className='graph-type-selector'>
      {JSX}    
    </div>
  )
}

export default GraphTypeSelector;

// TODO Stop manipulating the DOM and handle the 'active class' through state somehow.