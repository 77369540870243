import { Bar, BarChart, CartesianGrid } from "recharts";

const UserStatsBarChart = (props) => {

  const viewHeight = (window.innerHeight / 100);
  const viewWidth = (window.innerWidth / 100);

  return (
    <BarChart width={viewWidth * 10} height={viewHeight * 4} data={props.data}>
      <CartesianGrid horizontal={false} />
      <Bar dataKey='calls' fill='#0ab8cf' />
    </BarChart>
  )
}

export default UserStatsBarChart;