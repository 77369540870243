import './Welcome.scss';

const Welcome = (props) => {


  return(
    <div className='welcome'>
      <h1>Welkom op het dashboard!</h1>
      <p>Begin door in het menu een pagina te selecteren</p>
    </div>
  )
}

export default Welcome; 