import UserStatsBarChart from './UserStatsBarChart';
import './UserStatsTile.scss';

const UserStatsTile = (props) => {

  const statTypes = ['Calls Queued', 'Calls Answered', 'Calls Abandoned', 'Calls Bounced'];
  const dayAbbreviations = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];

  
  // TODO Make the header blue background / white text
  let headerJSX = <div className='user-stats-tile-row'>
                  {props.username}
                  <div className='user-stats-tile-row-stats'>
                    <div className='user-stats-tile-row-stats-wrapper'>
                      {dayAbbreviations.map((day, index) => <div key={'user-stats-header-day-' + index} className='user-stats-tile-row-stats-raw'>
                        {day}
                      </div>)}
                    </div>
                    <div className='user-stats-tile-row-stats-graph'>

                    </div>
                  </div>
                </div>;

  let rowsJSX = props.data.map((item, index) => {
    return(
      <div className='user-stats-tile-row'>
        {statTypes[index]}
        <div className='user-stats-tile-row-stats'>
          <div className='user-stats-tile-row-stats-wrapper'>
            {item.map((stat, id) => {
              return(
                <div key={'user-stats-raw-' + index + '-' + id} className='user-stats-tile-row-stats-raw'>
                  {stat.calls}
                </div>
              )
            })}
          </div>
          <div className='user-stats-tile-row-stats-graph'>
            <UserStatsBarChart data={item} />
          </div>
        </div>
      </div>
    )
  });

  return (
    <div className='user-stats-tile'>
      {headerJSX}
      {rowsJSX}
    </div>
  )
}

export default UserStatsTile;

// TODO Make the tile a link to each user's personal dashboard.