import './TopBar.scss';

const TopBar = (props) => {

  let JSX = <button>TEST</button>

  return (
    <div className='topbar'>
      PLACEHOLDER
    </div>
  )
}

export default TopBar;