export const allWeeks = [{
  week: 30
}, {
  week: 31
}, {
  week: 32
}, {
  week: 33
}, {
  week: 34
}, {
  week: 35
}, {
  week: 36
}, {
  week: 37
}, {
  week: 38
}, {
  week: 39
}, {
  week: 40
}, {
  week: 41
}]