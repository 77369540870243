export const days = [{
  name: 'monday',
  title: 'Maandag',
  color: '#ffa600',
}, {
  name: 'tuesday',
  title: 'Dinsdag',
  color: '#ff702a',
}, {
  name: 'wednesday',
  title: 'Woensdag',
  color: '#fe2f4b',
}, {
  name: 'thursday',
  title: 'Donderdag',
  color: '#e2006b',
}, {
  name: 'friday',
  title: 'Vrijdag',
  color: '#af0089',
}, {
  name: 'saturday',
  title: 'Zaterdag',
  color: '#5f009e',
}]